import styles from "./ConnectToWalletButton.module.css";

const ConnectToWalletButton = ({children}) => {
  return (
    <div className={styles["connectToWalletButton"]}>
      {children}
    </div>
  );
};

export default ConnectToWalletButton;
