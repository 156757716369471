import React from "react";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <div>
      <footer className={`py-3 fixed-bottom ${styles["footer"]}`}>
        <div className="container">
          <p className="m-0 text-center text-black">
            zedinstead.com | zedinstead.eth
          </p>
          <p className="m-0 text-center text-black">&copy; 2022</p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
