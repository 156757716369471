//FIRST TOKEN COLLECTION
//Mainnet NFT 1
//Opens Zed Cat Club Page
export const ZEDCATNFT = "0x37Ad6637A835DA66dE15495aD8C7AEd397B46E24";

//SECOND TOKEN COLLECTION
//Mainnet NFT 2
//Opens Inspiration Page
export const PUNK = "0x0d7f6D2A0c3bF990719ecA41C9bE130f8bAAb7AF";

//THIRD TOKEN ENS
//Mainnet ENS Domains
//Opens the ENS Domain Club
export const ENSNAMES = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
