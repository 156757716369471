import React from "react";
import ZedCat2 from "../images/zedcats/ZedCat2.jpg";
import ZedCat3 from "../images/zedcats/ZedCat3.jpg";
import ZedCat4 from "../images/zedcats/ZedCat4.jpg";
import ZedCat5 from "../images/zedcats/ZedCatENS.jpg";
import { useLookupAddress } from "@usedapp/core";
import { NavLink } from "react-router-dom";
import chat from "../images/chatrooms.jpg";

const ZedCats = ({
  account,
  tokenBalances: { firstTokenBalance, thirdTokenBalance },
}) => {
  const ens = useLookupAddress();

  return (
    <div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-7">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={ZedCat2}
                alt="Cards with waving Zedcats"
              />
            </div>
            <div className="col-lg-5">
              <br></br>
              <h1 className="font-weight-light">
                <b>🐈 Zed Cat Project 🐈</b>
              </h1>
              <div>
                <br></br>
              </div>
              <p>
                I am not a cat but I think they are pretty neat. To honor the
                original CryptoKitties, the first zedinstead NFT project is a
                collection of 420 Zed Cats, which act as a perpetual access
                token for all gated content across current and future projects.
                Each Zed Cat is randomly generated from five different features
                and created through a standard ERC-721 contract backed by IPFS.
                Most importanly, owners have access to the Zed Cat Club. Come
                explore the wide world of web3!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-6">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                  <h1 className="font-weight-bold">
                    <b>Cat Power! </b>
                  </h1>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                  Zed Cats have 5 different features: background, mountain,
                  hill, headband, and special. Special features include snow on
                  the summit, or sunshine with a cloud. There may even be a
                  skier or a snowboarder in the distance on the side of the
                  mountain, or a rocket in the corner.
                </p>
                <p></p>
                <center>
                  <p>
                    <strong>Items:</strong> 420 NFTs
                  </p>
                  <p>
                    <a href="https://etherscan.io/address/0x37ad6637a835da66de15495ad8c7aed397b46e24">
                      <strong>EtherScan:</strong> 🚀
                    </a>
                  </p>
                  <p>
                    <a href="https://opensea.io/collection/zedcats">
                      <strong>OpenSea:</strong> ⛵
                    </a>
                  </p>

                  <p>
                    You can mint a Zed Cat on the{" "}
                    <NavLink to="/nftcollections">NFT Collections page</NavLink>
                    .
                  </p>
                </center>
              </div>
            </div>

            <div className="col-lg-6">
              <p>
                <br></br>
              </p>
              <div>
                {(firstTokenBalance ? !firstTokenBalance.eq(0) : null) ? (
                  <NavLink to="/zedcatclub">
                    <img
                      className="img-fluid rounded mb-4 mb-lg-0"
                      src={ZedCat4}
                      alt=""
                    />
                  </NavLink>
                ) : (
                  <img
                    className="img-fluid rounded mb-4 mb-lg-0"
                    src={ZedCat3}
                    alt=""
                  />
                )}
              </div>
            </div>
            <p>
              <br></br>
            </p>
          </div>
          {(thirdTokenBalance ? !thirdTokenBalance.eq(0) : null) ? (
            <div className="row align-items-center my-6">
              <div className="col-lg-6">
                <div className="containText">
                  <p>
                    <br></br>
                  </p>
                  <center>
                    <h1 className="font-weight-bold">
                      <b>ENS Power! </b>
                    </h1>
                  </center>
                  <div>
                    <br></br>
                  </div>
                  <p>
                    Hey there{" "}
                    <strong>
                      <> {ens ?? account}</>
                    </strong>
                    ! It looks like you are one of the web3 wizards who own an{" "}
                    <strong>Ethereum Name Service (ENS)</strong> domain. To
                    celebrate, here is a special Zed Cat just for you that has
                    your ENS name embroidered into its headband!
                  </p>
                  <p>
                    There are new sites being created every day that allow you
                    to use your <strong>ENS</strong> in all sorts of cool ways
                    and the evergrowing list of integrations and apps can be
                    found here:{" "}
                    <a
                      href="https://ens.domains/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://ens.domains/
                    </a>
                    .
                  </p>
                  <p>
                    To be clear, the current Zed Cats that are available for
                    minting do not have ENS names on their headbands, however
                    there is a possibility of it being included as a feature in
                    Zed Cat 2: Electric Boogaloo so stay tuned.
                  </p>
                  <p>
                    For now, I'd like to use this space to showcase a site each
                    week that is using <strong>ENS</strong> in some pretty neat
                    or useful ways:
                  </p>
                  <br></br>
                  <center>
                    <a href="https://chatrooms.eth.link/#/rooms">
                      <img
                        src={chat}
                        className="rounded-corners"
                        width="20%"
                        alt=""
                      />
                      <p>chatrooms</p>
                    </a>
                  </center>
                  <br></br>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="head-text">
                  <div className="head-image">
                    <img
                      src={ZedCat5}
                      className="img-fluid rounded mb-4 mb-lg-0"
                      alt="Zedcats arranged around border of square, waving hello"
                    />
                  </div>
                  <div className="text-on-image">
                    <p>
                      <center> {ens ?? account} </center>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <br></br>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ZedCats;
