import React from "react";
import Phones from "../images/Phones.jpg";

const Contact = () => {
  return (
    <div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <br></br>
              <center>
                <h1 className="font-weight-light">
                  <b>📇 Contact 📇</b>
                </h1>
              </center>
              <div>
                <br></br>
              </div>
              <p>
                The best way to reach zedinstead is by carrier pigeon or by
                Western Union telegram. The following ways are also acceptable:
              </p>

              <div className="contain6">
                <center>
                  <div className="row align-items-center my-5">
                    <div className="col-lg-5">
                      <p>
                        <a
                          href="https://twitter.com/zedinstead1"
                          target="_blank"
                          rel="noreferrer"
                        >
                          🐦 Twitter
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.reddit.com/user/zedinstead/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          🤖 Reddit
                        </a>
                      </p>
                      <p>
                        <a href="https://github.com/zedinstead" target="_blank" rel="noreferrer">
                          💾 Github{" "}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-5">
                      <p>
                        <a
                          href="mailto: zedinstead1@outlook.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          📧 Email 1
                        </a>
                      </p>
                      <p>
                        <a href="mailto: zedinstead1@gmail.com" target="_blank" rel="noreferrer">
                          📬 Email 2
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://discordapp.com/users/zedinstead#0687"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ⌨️ Discord
                        </a>
                      </p>
                    </div>
                  </div>
                </center>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div className="contain1">
                <img src={Phones} alt="Phone, Email, Pager and Rounded Chat Notification Icons" className="img-fluid rounded mb-4 mb-lg-0" />
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
