import React from "react";
import styles from "./DevAccess.module.css";
import ZedDevKeyCard from "../../images/ZedDevKeyCard.png";
import ZedDevKeyCard2 from "../../images/ZedDevKeyCard2.png";
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
  loaderUrl: "FIRSTDFVTEST/build/FIRSTDFVTEST.loader.js",
  dataUrl: "FIRSTDFVTEST/build/FIRSTDFVTEST.data",
  frameworkUrl: "FIRSTDFVTEST/build/FIRSTDFVTEST.framework.js",
  codeUrl: "FIRSTDFVTEST/build/FIRSTDFVTEST.wasm",
});

const DevAccess = ({ devAccessL2 }) => {
  return (
    <div className={`container ${styles["DevAccess"]}`}>
      <div className="contain2">
        <div className="row align-items-center my-5">
          <div className={`${styles["dev-cards-container"]}`}>
            <div
              className={
                devAccessL2.firstTokenBalance?.totalNum &&
                devAccessL2.secondTokenBalance?.totalNum
                  ? `${styles.dot} ${styles.green}`
                  : `${styles.dot} ${styles.red}`
              }
            ></div>
            <h3>
              {devAccessL2.firstTokenBalance?.totalNum &&
              devAccessL2.secondTokenBalance?.totalNum
                ? "You own both Cards"
                : "You own 1 of 2 Cards"}
            </h3>
            <div
              className={
                devAccessL2.firstTokenBalance?.totalNum &&
                devAccessL2.secondTokenBalance?.totalNum
                  ? `${styles.dot} ${styles.green}`
                  : `${styles.dot} ${styles.red}`
              }
            ></div>
          </div>
          <div
            className={`${styles["dev-cards-container"]} ${styles["zed-cat-container"]}`}
          >
            <div className={`${styles["zed-cat-card-image"]}`}>
              {devAccessL2.firstTokenBalance?.totalNum ? (
                <img
                  src={ZedDevKeyCard}
                  alt="Yellow business card with cat waving on it"
                />
              ) : null}
            </div>
            <div className={`${styles["zed-cat-card-image"]}`}>
              {devAccessL2.secondTokenBalance?.totalNum ? (
                <img
                  src={ZedDevKeyCard2}
                  alt="Yellow business card with cat waving on it"
                />
              ) : null}
            </div>
            </div>
            </div>
            <div>
            <div class="row align-items-center">
              <center>
            <h1 class="font-weight-light"><b>Deep Value: The Game 1.0</b></h1>
            </center>
            <div><br></br></div>
      <center><Unity style={{
        width: "100%",
      }}
      unityContext={unityContext} />
      
      
      </center>
      
    </div>
    </div>
         
        
      </div>
    </div>
  );
};

export default DevAccess;
