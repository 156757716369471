import React from "react";
import styles from "./PDFSelector.module.css";

const PDFSelector = ({ sortBy, PDFs, setSelectedPDF }) => {
  let handlePDFChange = (e) => {
    setSelectedPDF(e.target.value);
  };

  if (sortBy) {
    sortBy(PDFs);
  }

  return (
    <select onChange={handlePDFChange} className={`${styles["PDFSelector"]}`}> 
      <option value=""> -- Select a PDF -- </option>
      {PDFs.map((PDF) => (
        <option value={PDF.value} key={PDF.value}>
          {PDF.date}&nbsp;|&nbsp;{PDF.author}&nbsp;|&nbsp;{PDF.label}
        </option>
      ))}
    </select>
  );
};

export default PDFSelector;
