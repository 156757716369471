import React, { useState } from "react";

import cat1 from "../images/cat1.png";
import cat2 from "../images/cat2.png";

const Home = () => {
  const [over, setOver] = useState(false);

  return (
    <div className="home">
      <br></br>
      <center>
        <div>
          <img
            src={over ? cat2 : cat1}
            alt="Waving Zedcat"
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
          />
        </div>
      </center>

      <div className="container">
        <center>
          <div className="contain1">
            <div className="col-lg-5">
              <p>zedinstead: the website </p>
              <br></br>
                       
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Home;
