import React from "react";
import styles from "./Modal.module.css";

const Modal = ({ setShowModal, children }) => {
  return (
    <div className={styles["modal"]}>
      <div className={styles["modal-content"]}>
        {children}
        <div
          className={styles["close"]}
          onClick={() => {
            setShowModal(false);
          }}
        >
          &times;
        </div>
      </div>
    </div>
  );
};

export default Modal;
