import React from "react";
import { NavLink } from "react-router-dom";
import ConnectToWalletButton from "../ConnectToWalletButton/ConnectToWalletButton";

const Navbar = ({
  account,
  handleDeactivateAccount,
  lookupValue,
  tokenBalances: { firstTokenBalance, secondTokenBalance, thirdTokenBalance },
  setShowModal,
  devAccessL2,
}) => {
  return (
    <div>
      <div className="navigation">
        <nav className="navbar navbar-custom navbar-expand-md navbar-light">
          <div className="container">
            <NavLink className="navbar-brand" to="/">
              zedinstead
            </NavLink>
            <div>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/lunchtray">
                    pixel lunch trays
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/zedcats">
                    zed cats
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/NFTCollections">
                    nft collections
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact">
                    contact
                  </NavLink>
                </li>
                {(firstTokenBalance ? !firstTokenBalance.eq(0) : null) ? (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/zedcatclub">
                      zed cat club
                    </NavLink>
                  </li>
                ) : null}
                {(secondTokenBalance ? !secondTokenBalance.eq(0) : null) ||
                devAccessL2.libraryCardBalance?.totalNum ? (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/inspiration">
                      inspiration gallery
                    </NavLink>
                  </li>
                ) : null}
                {devAccessL2.firstTokenBalance?.totalNum ||
                devAccessL2.secondTokenBalance?.totalNum ? (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/devaccess">
                      dev access
                    </NavLink>
                  </li>
                ) : null}
                <li className="nav-item">
                  {account ? (
                    <ConnectToWalletButton>
                      <button onClick={() => handleDeactivateAccount()}>
                        {" "}
                        {(
                          thirdTokenBalance ? !thirdTokenBalance.eq(0) : null
                        ) ? (
                          <>
                            <span id="lookup">{lookupValue}</span>
                          </>
                        ) : (
                          <>
                            {account &&
                              `${account.slice(0, 6)}...${account.slice(
                                account.length - 4,
                                account.length
                              )}`}
                          </>
                        )}{" "}
                      </button>
                    </ConnectToWalletButton>
                  ) : (
                    <ConnectToWalletButton>
                      <button
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Connect To Wallet
                      </button>
                    </ConnectToWalletButton>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
