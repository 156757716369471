import React from "react";
import ZedCat5 from "../images/zedcats/ZedCat5.jpg";
import ZedCat6 from "../images/zedcats/ZedCat6.jpg";

const ZedCatClub = () => {
  return (
    <div className="container">
      <div className="contain1">
        <div className="row align-items-center my-5">
          <img src={ZedCat5} alt="Three Zedcats Waving at User" />
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="contain9">
            <div className="row align-items-center my-5">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                  <h1 className="font-weight-bold">
                    <b>😸 THE ZED CAT CLUB 😸</b>
                  </h1>
                </center>
                <div>
                  <br></br>
                </div>
                <p>Dear Zed Cat Club Member,</p>
                <p>
                  If you're reading this, you've made it! And if you've come
                  this far, maybe you're willing to come a little further. You
                  remember the name of the town, don't you? I could use a good
                  cat to help me get my project on wheels. I'll keep an eye out
                  for you and the chessboard ready. Remember, hope is a good
                  thing, maybe the best of things and no good thing ever dies. I
                  will be hoping that this message finds you, and finds you
                  well.
                </p>
                <p>Your friend,</p>
                <p>&nbsp; -zedinstead</p>
                <center>
                  <p>
                    | <a href="https://discord.gg/J3cb2286Rg">Discord</a> |
                  </p>
                </center>
                <div className="row align-items-center my-5">
                  <img src={ZedCat6} alt="Three Zedcats Waving at User" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5">
              <p>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZedCatClub;
