import React from "react";

const DisplayContent = ({selectedPDF}) => {
  return (
    <>
      <iframe
        id="IPFS PDF LIBRARY PORTAL"
        title="IPFS PDF LIBRARY PORTAL"
        width="90%"
        height="900"
        src={selectedPDF}
      ></iframe>
    </>
  );
};

export default DisplayContent;
