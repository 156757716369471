import React from "react";
import ConnectMintButton from "../components/MintButtons/ConnectMintButton"
import ConnectMintButton2 from "../components/MintButtons/ConnectMintButton2";
import Collections from "../images/Collections.png";
import Periodicals from "../images/Periodicals.jpg";
import DueDiligence from "../images/DueDiligence.jpg";
import ZedCat1 from "../images/zedcats/ZedCat1.jpg";
import LibraryCard from "../images/LibraryCard.gif";

const NFTCollections = ({account}) => {
  return (
    <div className="NFTCollections">
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-7">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={Collections}
                alt=""
              />
            </div>
            <div className="col-lg-5">
              <h1 className="font-weight-bold">
                <b>🎴 NFT Collections 🎴</b>
              </h1>
              <div>
                <br></br>
              </div>
              <p>
                There is currently 1 NFT project on Layer 1 available for minting
                here on this page and 1 NFT project on Loopring's Layer 2 that can be purchased by reaching out to zedinstead. Integrating these NFTs into 
                web3 games and virtual experiences is the long-term vision for these and upcoming
                projects. More Layer 2 NFTs are coming soon so stay tuned.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-6">
            <div className="col-lg-6">
              <center>
                <p>
                  <br></br>
                </p>
                <h1 className="font-weight-bold">
                  <b>Zed Cat Collection</b>
                </h1>
                <div>
                  <br></br>
                </div>

                <p>
                  The Zed Cat Collection is now available for minting. This
                  collection acts as an access token for all ongoing and future
                  zedinstead projects and are represented by a randomly
                  generated cat. Owners have exclusive access to the Zed Cat
                  Club, alongside all other zedinstead gated pages.
                </p>
                <p>
                  <strong>Cost:</strong> 0.01 eth &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Items:</strong> 420 NFTs
                </p>
                <p>
                  <a href="https://etherscan.io/address/0x37ad6637a835da66de15495ad8c7aed397b46e24">
                    <strong>EtherScan:</strong> 🚀
                  </a>
                </p>
                <p>
                  <a href="https://opensea.io/collection/zedcats">
                    <strong>OpenSea:</strong> ⛵
                  </a>
                </p>
                <p>
                  <br></br>
                </p>
                <p>
                  {account ? <ConnectMintButton2>MINT</ConnectMintButton2> : null}
                </p>
              </center>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={ZedCat1}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-6">
            <div className="col-lg-6">
              <center>
                <p>
                  <br></br>
                </p>
                <h1 className="font-weight-bold">
                  <b>Library Card issued by zedinstead</b>
                </h1>
                <div>
                  <br></br>
                </div>

                <p>
                  This is the first Loopring Layer 2 NFT by zedinstead and available for purchase through the form on the homepage. Owners currently have access to the 'Inspiration Gallery' page. The page also includes an exhibition space currently showcasing a never-before-seen meme by u/ButtFarm69.
                </p>
                <p>
                  <strong>Cost:</strong> 5 LRC .001 ETH &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <strong>Items:</strong> 420
                </p>
                <p>
                  <strong>EtherScan:</strong> Not Available
                </p>
                <p>
                  <strong>OpenSea:</strong> Not Available
                </p>
                <p>
                  <br></br>
                </p>
                <p>
                  <br></br>
                </p>
              </center>
            </div>

            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={LibraryCard}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5">
              <p>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCollections;
