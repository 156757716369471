import React, { useState, useCallback } from "react";
import PDFs from "./PDFContent";
import DisplayContent from "./DisplayContent";
import PDFSelector from "./PDFSelector/PDFSelector";

const actions = [
  { name: "Author", type: "b1" },
  { name: "Publication Date", type: "b2" },
];

function IPFSPortal() {
  const [contentType, setContentType] = useState("b1");
  let [selectedPDF, setSelectedPDF] = useState();

  const sortBy = useCallback(
    (PDFs) => {
      PDFs.sort(function (a, b) {
        if (contentType == "b1") {
          if (a.author < b.author) {
            return -1;
          }
          if (a.author > b.author) {
            return 1;
          }
          return 0;
        } else {
          var dateA = new Date(a.date),
            dateB = new Date(b.date);
          return dateA - dateB;
        }
      });
    },
    [contentType]
  );

  return (
    <>
      Sort by{" "}
      {actions.map((action) => (
        <button
          key={action.type}
          style={{
            backgroundColor:
              action.type === contentType ? "lightblue" : "white",
          }}
          onClick={() => setContentType(action.type)}
        >
          {action.name}
        </button>
      ))}
      <PDFSelector
        sortBy={sortBy}
        PDFs={PDFs}
        setSelectedPDF={setSelectedPDF}
      />
      <DisplayContent selectedPDF={selectedPDF} />
    </>
  );
}

export default IPFSPortal;
