import React, { useState, useEffect } from "react";
import Navbar from "./components/Navigation/Navbar";
import Footer from "./components/Navigation/Footer";
import Modal from "./components/Modal/Modal";
import PrivateRoute from "./components/PrivateRoute";
import ConnectToWalletButton from "./components/ConnectToWalletButton/ConnectToWalletButton";
import MetaMaskIcon from "./components/ConnectToWalletButton/MetaMaskIcon";
import WalletConnectIcon from "./components/ConnectToWalletButton/WalletConnectIcon";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEthers, useTokenBalance } from "@usedapp/core";
import { getDefaultProvider } from "ethers";
import Home from "./pages/Home";
import ZedCats from "./pages/ZedCats";
import LibraryProject from "./pages/LibraryProject";
import NFTCollections from "./pages/NFTCollections";
import Contact from "./pages/Contact";
import LunchTray from "./pages/LunchTray";
import ZedCatClub from "./pages/ZedCatClub";
import Inspiration from "./pages/Inspiration";
import DevAccess from "./pages/DevAccess/DevAccess";
import { ZEDCATNFT, PUNK, ENSNAMES } from "./configs/constants";
import WalletConnectProvider from "@walletconnect/web3-provider";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-image-gallery/styles/css/image-gallery.css";
import styles from "./App.module.css";
import connectWalletStyles from "./components/ConnectToWalletButton/ConnectToWalletContent.module.css";

const App = () => {
  const { account, activate, deactivate, activateBrowserWallet } = useEthers();
  const [lookupValue, setLookupValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const [walletConnectProvider, setWalletConnectProvider] = useState();
  const [devAccessL2, setDevAccessL2] = useState({
    firstTokenBalance: null,
    secondTokenBalance: null,
    libraryCardBalance: null,
  });
  const firstTokenBalance = useTokenBalance(ZEDCATNFT, account);
  const secondTokenBalance = useTokenBalance(PUNK, account);
  const thirdTokenBalance = useTokenBalance(ENSNAMES, account);

  useEffect(() => {
    if (account) {
      //Get main L1 account
      try {
        const mainnetProvider = getDefaultProvider();
        mainnetProvider.lookupAddress(account).then((result) => {
          setLookupValue(result);
        });
        setShowModal(false);
      } catch (e) {
        //console.log(e);
      }

      const axiosLoopringL2Call = async () => {
        let responseDevAccessLayerTwo, responseDevAccessLayerTwo2, responseDevAccessLayerTwo3;
        let res;

        //See if account exists on L2
        try {
          res = await axios.get(
            `https://api3.loopring.io/api/v3/account?owner=${account}`
          );
        } catch (e) {
          console.log(e);
        }

        //Check if Dev Access cards are present on L2 wallet
        if (res.data.accountId) {
          try {
            const l2Res1 = await axios.get(
              `https://api3.loopring.io/api/v3/user/nft/balances?accountId=${res.data.accountId}&nftDatas=0x0d322aef2a8558b1d2cfc5a8175e1f29cf119980abd62bc4967a9c36df8f930e`,

              {
                headers: {
                  "X-API-KEY":
                    "zCwvZZPeqazBxeP6wdwULxiaZppJXvoI4G2088eAGtR7YNSO7TKHpn9MxCsTdM2m",
                },
              }
            );
            responseDevAccessLayerTwo = l2Res1.data;
          } catch (e) {
            responseDevAccessLayerTwo = null;
          }
          try {
            const l2Res2 = await axios.get(
              `https://api3.loopring.io/api/v3/user/nft/balances?accountId=${res.data.accountId}&nftDatas=0x30003dfa1c429fe21ef2b90d29dc56b7cabe519b8c7eada30ccc975c7c077d88`,

              {
                headers: {
                  "X-API-KEY":
                    "zCwvZZPeqazBxeP6wdwULxiaZppJXvoI4G2088eAGtR7YNSO7TKHpn9MxCsTdM2m",
                },
              }
            );
            responseDevAccessLayerTwo2 = l2Res2.data;
          } catch (e) {
            responseDevAccessLayerTwo2 = null;
          }

          //library card
          try {
            const l2Res3 = await axios.get(
              `https://api3.loopring.io/api/v3/user/nft/balances?accountId=${res.data.accountId}&nftDatas=0x0af6a6fb82f8ffa77378bb3b7060ed2cb2b502bdae69b74facd1f3b202965b82`,

              {
                headers: {
                  "X-API-KEY":
                    "zCwvZZPeqazBxeP6wdwULxiaZppJXvoI4G2088eAGtR7YNSO7TKHpn9MxCsTdM2m",
                },
              }
            );
            responseDevAccessLayerTwo3 = l2Res3.data;
          } catch (e) {
            responseDevAccessLayerTwo3 = null;
          }
          setDevAccessL2({
            firstTokenBalance: responseDevAccessLayerTwo,
            secondTokenBalance: responseDevAccessLayerTwo2,
            libraryCardBalance: responseDevAccessLayerTwo3,
          });
        } else {
          setDevAccessL2({
            firstTokenBalance: null,
            secondTokenBalance: null,
            libraryCardBalance: null,
          });
        }
      };
      axiosLoopringL2Call();
    } else {
      setDevAccessL2({
        firstTokenBalance: null,
        secondTokenBalance: null,
        libraryCardBalance: null,
      });
    }
  }, [account]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [showModal]);

  const handleDeactivateAccount = async () => {
    if (walletConnectProvider) {
      try {
        await walletConnectProvider.disconnect();
        setWalletConnectProvider(null);
        localStorage.removeItem("walletconnect");
      } catch (e) {
        //console.log(e);
      }
    }
    deactivate();
  };

  const onWalletConnectConnection = async () => {
    try {
      const provider = new WalletConnectProvider({
        infuraId: "fbe8df56dbb04f34bfda0e37241be52e",
      });
      await provider.enable();
      await activate(provider);
      setWalletConnectProvider(provider);
    } catch (error) {
      if (!error.message.toLowerCase() === "user closed modal") {
        console.error(error.message);
      }
    }
  };

  return (
    <>
      {showModal ? (
        <Modal setShowModal={setShowModal}>
          <div className={connectWalletStyles["connect-to-wallet-content"]}>
            <h3>Connect Your Wallet with an option below</h3>
            <div>
              <ConnectToWalletButton>
                <button onClick={onWalletConnectConnection}>
                  WalletConnect
                  <WalletConnectIcon />
                </button>
              </ConnectToWalletButton>
              <ConnectToWalletButton>
                <button onClick={activateBrowserWallet}>
                  MetaMask
                  <MetaMaskIcon />
                </button>
              </ConnectToWalletButton>
            </div>
          </div>
        </Modal>
      ) : null}
      <Router>
        <Navbar
          account={account}
          activate={activate}
          handleDeactivateAccount={handleDeactivateAccount}
          lookupValue={lookupValue}
          tokenBalances={{
            firstTokenBalance,
            secondTokenBalance,
            thirdTokenBalance,
          }}
          setShowModal={setShowModal}
          devAccessL2={devAccessL2}
        />
        <div className={styles["content-body"]}>
          <Switch>
            <Route path="/zedcats">
              <ZedCats
                tokenBalances={{ firstTokenBalance, thirdTokenBalance }}
                account={account}
              />
            </Route>
            <Route path="/libraryproject">
              <LibraryProject />
            </Route>
            <Route path="/lunchtray">
              <LunchTray />
            </Route>
            <Route path="/NFTCollections">
              <NFTCollections account={account} />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <PrivateRoute
              path="/inspiration"
              filter={secondTokenBalance ? !secondTokenBalance.eq(0) : null}
              filter={devAccessL2.libraryCardBalance?.totalNum}
              component={Inspiration}
              devAccessL2={devAccessL2}
            />
            <PrivateRoute
              path="/zedcatclub"
              filter={firstTokenBalance ? !firstTokenBalance.eq(0) : null}
              component={ZedCatClub}
            />
            <PrivateRoute
              path="/devaccess"
              filter={
                devAccessL2.firstTokenBalance?.totalNum ||
                devAccessL2.secondTokenBalance?.totalNum
              }
              devAccessL2={devAccessL2}
              component={DevAccess}
            />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;
