import React from "react";
import { Link } from "react-router-dom";
import LunchTrays from "../images/menutrayssix.jpg";
import Cafe from "../images/cafe.jpg";


const LunchTray = () => {
  return (
    <div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={LunchTrays}
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <br></br>
              <h1 className="font-weight-light">
                <b>
                🍱Pixel Lunch Tray Project🍱
                </b>
              </h1>
              <div>
                <br></br>
              </div>
              <p>
                The Pixel Lunch Tray Project consists of 10,000 unique lunch trays expected to be minted on Loopring's Layer2 and hopefully available in the GameStop NFT Marketplace. The artwork for this project is complete and the interactive experience is currently under construction.  

              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain2">
          <div className="row align-items-center my-5">
            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>PixelLunchTray.com </b>
                </h1>
                </center>
                <div>
                  <br></br>
                </div>
                <p>
                  The latest zedinstead project has a new website! There you can find out information about the artwork and learn about the roadmap. There is an expected airdrop coming a few months after launch and you can also see the upcoming cafeteria experience where NFT owners will be able to walk around the virtual pixel space and interact with trays on the tables. 
                  <br></br>
                  <br></br>
                  There is still a lot of work to be done but construction is underway. 
                </p>
                <p></p>
                <center>
                  <p>
                     |{" "}
                    <a href="http://pixellunchtray.com/">
                      PixelLunchTray.com
                    </a>{" "} |
                    
                  </p>
                </center>
                <p>
                  <br></br>
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="containText">
                <p>
                  <br></br>
                </p>
                <center>
                <h1 className="font-weight-bold">
                  <b>The Cafeteria </b>
                </h1>
                </center>
             
                <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={Cafe}
                alt=""
              />
                <p>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
     
      <div className="container">
        <div className="contain1">
          <div className="row align-items-center my-5">
            <div className="col-lg-5">
              <p>
                <br></br>
              </p>
              <p>
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LunchTray;
